var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._m(0), _c('div', {
    staticClass: "bg-white mt-3 flex-col rounded-lg shadow-sm p-4"
  }, [_c('div', {
    staticClass: "flex justify-between"
  }, [_vm._m(1), _c('div', [_c('button', {
    staticClass: "flex items-center bg-blue-700 text-white p-1 px-2 rounded-lg focus:outline-none"
  }, [_c('router-link', {
    staticClass: "text-white no-underline items-center w-full flex list-none hover:no-underline",
    attrs: {
      "to": {
        path: "/setting/otp-management/edit"
      }
    }
  }, [_c('svg', {
    staticClass: "w-6 h-6 no-underline text-white dark:text-white",
    attrs: {
      "aria-hidden": "true",
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "currentColor",
      "viewBox": "0 0 24 24"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "d": "M11.32 6.176H5c-1.105 0-2 .949-2 2.118v10.588C3 20.052 3.895 21 5 21h11c1.105 0 2-.948 2-2.118v-7.75l-3.914 4.144A2.46 2.46 0 0 1 12.81 16l-2.681.568c-1.75.37-3.292-1.263-2.942-3.115l.536-2.839c.097-.512.335-.983.684-1.352l2.914-3.086Z",
      "clip-rule": "evenodd"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "d": "M19.846 4.318a2.148 2.148 0 0 0-.437-.692 2.014 2.014 0 0 0-.654-.463 1.92 1.92 0 0 0-1.544 0 2.014 2.014 0 0 0-.654.463l-.546.578 2.852 3.02.546-.579a2.14 2.14 0 0 0 .437-.692 2.244 2.244 0 0 0 0-1.635ZM17.45 8.721 14.597 5.7 9.82 10.76a.54.54 0 0 0-.137.27l-.536 2.84c-.07.37.239.696.588.622l2.682-.567a.492.492 0 0 0 .255-.145l4.778-5.06Z",
      "clip-rule": "evenodd"
    }
  })]), _c('span', {
    staticClass: "ml-1 no-underline hover:no-underline"
  }, [_vm._v("Edit")])])], 1)])]), _c('div', {
    staticClass: "relative inline-block text-left w-1/2 ml-2"
  }, [_c('label', {
    staticClass: "text-sm font-semibold"
  }, [_vm._v(" OTP Resend Limit ")]), _c('div', {
    staticClass: "block text-gray-500"
  }, [_vm._v(" " + _vm._s(_vm.selectedDataResendLimit) + " ")]), _c('label', {
    staticClass: "text-sm font-semibold"
  }, [_vm._v(" OTP Time Limit ")]), _c('div', {
    staticClass: "block text-gray-500"
  }, [_vm._v(" " + _vm._s(_vm.selectedDataOtpTimeLimit) + " minute ")]), _c('label', {
    staticClass: "text-sm font-semibold mt-2"
  }, [_vm._v(" Deauthorization Attempt Limit ")]), _c('div', {
    staticClass: "block text-gray-500"
  }, [_vm._v(" " + _vm._s(_vm.selectedDataDeauthDeviceLimit) + " per week ")]), _c('label', {
    staticClass: "text-sm font-semibold mt-2"
  }, [_vm._v(" Device Limit ")]), _c('div', {
    staticClass: "block text-gray-500"
  }, [_vm._v(" " + _vm._s(_vm.selectedDataMaxDeviceLimit) + " ")])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "bg-white p-4 text-gray-500 my-4 rounded-lg shadow-sm"
  }, [_c('span', {
    staticClass: "py-3 text-gray-800"
  }, [_vm._v(" Config Management "), _c('h5', {
    staticClass: "mt-1 text-2xl"
  }, [_vm._v("Config Management")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h4', {
    staticClass: "block w-full text-xl font-bold mb-4"
  }, [_vm._v("Config Management Details")])]);
}]

export { render, staticRenderFns }