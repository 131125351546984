<template>
    <div>
        <div class="bg-white p-4 text-gray-500 my-4 rounded-lg shadow-sm">
            <span class="py-3 text-gray-800">
                Config Management
                <h5 class="mt-1 text-2xl">Config Management</h5>
            </span>
        </div>

        <div class="bg-white mt-3 flex-col rounded-lg shadow-sm p-4">

            <div class="flex justify-between">
                <div>
                    <h4 class="block w-full text-xl font-bold mb-4">Config Management Details</h4>
                </div>
                <div>
                    <button class="flex items-center bg-blue-700 text-white p-1 px-2 rounded-lg focus:outline-none">
                        <router-link class="text-white no-underline items-center w-full flex list-none hover:no-underline" :to="{ path: `/setting/otp-management/edit`}">
                        <svg class="w-6 h-6 no-underline text-white dark:text-white" aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                            viewBox="0 0 24 24">
                            <path fill-rule="evenodd"
                                d="M11.32 6.176H5c-1.105 0-2 .949-2 2.118v10.588C3 20.052 3.895 21 5 21h11c1.105 0 2-.948 2-2.118v-7.75l-3.914 4.144A2.46 2.46 0 0 1 12.81 16l-2.681.568c-1.75.37-3.292-1.263-2.942-3.115l.536-2.839c.097-.512.335-.983.684-1.352l2.914-3.086Z"
                                clip-rule="evenodd" />
                            <path fill-rule="evenodd"
                                d="M19.846 4.318a2.148 2.148 0 0 0-.437-.692 2.014 2.014 0 0 0-.654-.463 1.92 1.92 0 0 0-1.544 0 2.014 2.014 0 0 0-.654.463l-.546.578 2.852 3.02.546-.579a2.14 2.14 0 0 0 .437-.692 2.244 2.244 0 0 0 0-1.635ZM17.45 8.721 14.597 5.7 9.82 10.76a.54.54 0 0 0-.137.27l-.536 2.84c-.07.37.239.696.588.622l2.682-.567a.492.492 0 0 0 .255-.145l4.778-5.06Z"
                                clip-rule="evenodd" />
                        </svg>
                        <span class="ml-1 no-underline hover:no-underline">Edit</span>
                        </router-link>
                    </button>
                    
                </div>
            </div>
            <div class="relative inline-block text-left w-1/2 ml-2">
                <label class="text-sm font-semibold">
                    OTP Resend Limit
                </label>
                <div class="block text-gray-500">
                    {{ selectedDataResendLimit }}
                </div>
                <label class="text-sm font-semibold">
                    OTP Time Limit
                </label>
                <div class="block text-gray-500">
                    {{ selectedDataOtpTimeLimit }} minute
                </div>
                
                <label class="text-sm font-semibold mt-2 ">
                    Deauthorization Attempt Limit
                </label>
                <div class="block text-gray-500">
                    {{ selectedDataDeauthDeviceLimit }} per week
                </div>
                <label class="text-sm font-semibold mt-2">
                    Device Limit
                </label>
                <div class="block text-gray-500">
                    {{ selectedDataMaxDeviceLimit }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    data() {
        return {
            selectedDataResendLimit: null,
            selectedDataOtpTimeLimit: null,
            selectedDataMaxDeviceLimit: null,
            selectedDataDeauthDeviceLimit: null,
        };
    },
    computed: {
        ...mapState({
            isLoading: (state) => state.ManageOtp.isLoading,
            isError: (state) => state.ManageOtp.isError,
            totalRows: (state) => state.ManageOtp.totalRows,
            items: (state) => state.ManageOtp.items,
        }),
    },
    mounted() {
        this.getOtp();
    },
    methods: {
        ...mapActions('ManageOtp', ['fetchManageOtp']),
        getOtp() {
            this.fetchManageOtp().then(response => {
                if (response.data) {
                    const data = response.data.data;

                    const otpResendLimit = data.find(item => item.name === "OTP_RESEND_LIMIT");
                    const otpTimeLimit = data.find(item => item.name === "OTP_TIME_LIMIT");
                    const maxDeviceLimit = data.find(item => item.name === "MAX_DEVICE_LIMIT");
                    const deauthDeviceLimit = data.find(item => item.name === "DEAUTH_DEVICE_LIMIT");

                    this.selectedDataResendLimit = otpResendLimit.value;
                    this.selectedDataOtpTimeLimit = otpTimeLimit.value;
                    this.selectedDataMaxDeviceLimit = maxDeviceLimit.value;
                    this.selectedDataDeauthDeviceLimit = deauthDeviceLimit.value;
                }
            });
        },
    },

};
</script>

<style scoped>
/* Add your styles here */
</style>